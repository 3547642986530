const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

import api from '@/api/api';

export default class AdmissionRequest {
    data = {
        token_auth: "",
        url: "",
        title:"",
        message:"",
        reference: "",
        referal_id: "",
        invited_client_id: "",
        rfc: "",
        city: "",
        curp: "",
        name: "",
        state: "",
        gender: "",
        regime: "",
        status: "",
        street: "",
        suburb: "",
        country: "",
        birthday: "",
        latitude: "",
        longitude: "",
        city_birth: "",
        ext_number: "",
        int_number: "",
        geolocation: "",
        middle_name: "",
        nationality: "MEXICANA",
        postal_code: "",
        state_birth: "",
        municipality: "",
        country_birth: "",
        maternal_name: "",
        paternal_name: "",
        marital_status: "",
        education_level: "",
        residence_start: "",
        residence_status: "",
        enrollment_fee_payment_date:"",
        adress_attributes: {
            city: "",
            state: "",
            status: "",
            street: "",
            suburb: "",
            country: "",
            latitude: "",
            longitude: "",
            ext_number: "",
            int_number: "",
            postal_code: "",
            municipality: "",
            residence_start: "",
            residence_status: "",
            between_street_one: "",
            between_street_two: ""
        },
        spouse_attributes: {
            rfc: "",
            name: "",
            gender: "",
            middle_name: "",
            maternal_name: "",
            paternal_name: ""
        },
        between_street_one: "",
        between_street_two: "",
        municipality_birth: "",
        identification_type: "",
        work_info_attributes: {
            status: "",
            category: "",
            occupation: "",
            supervisor: "",
            active_since: "",
            business_name: "",
            laboral_status: "",
            employee_number: [
                ""
            ],
            principal_occupation: ""
        },
        workplace_attributes: {
            city: "",
            name: "",
            state: "",
            status: "",
            street: "",
            suburb: "",
            country: "",
            employer: "",
            center_key: "",
            ext_number: "",
            int_number: "",
            postal_code: "",
            municipality: "",
            adress_attributes: {
                city: "",
                state: "",
                status: "",
                street: "",
                suburb: "",
                country: "",
                latitude: "",
                longitude: "",
                ext_number: "",
                int_number: "",
                postal_code: "",
                municipality: "",
                residence_start: "",
                residence_status: "",
                between_street_one: "",
                between_street_two: ""
            },
            between_street_one: "",
            between_street_two: "",
            representative_name: "",
            contact_info_attributes: {
                0: {
                    value: "",
                    status: "",
                    extension: "",
                    contact_type: "telefono"
                }
            }
        },
        client_classification: "",
        enrollment_fee_method: "",
        identification_number: "",
        salary_info_attributes: {
            liquidity: "",
            monthly_income: "",
            income_frecuency: ""
        },
        bank_account_attributes: {
            0: {
                account_number: "",
                account_type: "",
                bank_name: "",
                expiration_month: "",
                expiration_year: ""
            },
        },
        contact_info_attributes: {
            0: {
                value: "",
                status: "",
                extension: "",
                contact_type: "celular"
            },
        },
        beneficiaries_attributes: {},
        public_office_attributes: {
            0: {
                relationship: "titular",
                positions_name: "",
                has_had_public_positions: false,
                end_date_public_positions: "",
                start_date_public_positions: ""
            },
            1: {
                relationship: "familiar",
                positions_name: "",
                has_had_public_positions: false,
                end_date_public_positions: "",
                start_date_public_positions: ""
            }
        },
        saving_registration_attributes: {
            amount: "",
            status: "",
            date_of_end: "",
            date_of_start: "",
            payment_method: "",
            payment_frecuency: "",
            number_of_contribution: ""
        }
    };

    save = async(admissionRequest) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/assisted_registration`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = admissionRequest;

        return await api.httpPost(API_URL, config, params).catch(err => { return err });
    };

    registerHistoryMissingDocuments = async(params) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/sent_email_history/register`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        return await api.httpPost(API_URL, config, params).catch(err => { return err });
    };

    getHistoryMissingDocuments = async (token_auth, table_type, table_id, process) => {
        const resource = `/${business}/sent_email_history/index?token_auth=${token_auth}&table_type=${table_type}&table_id=${table_id}&process=${process}`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        // const params = { withCredentials: true, token_auth: token };

        return await api.httpGet(API_URL, { config },{}).catch(err => { return err });
    }

    getUnfinishedRequest = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_unfinished_requests?token_auth=${token}`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { withCredentials: true, token_auth: token };

        return await api.httpGet(API_URL, { params, config }).catch(err => { return err });
    }

    assingAnalystLogged = async (token, personId, admissionRequestId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/assing_analyst_logged?token_auth=${token}`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { 
            token_auth: token,
            person_id: personId,
            admission_request_id: admissionRequestId
        };

        return await api.httpPost(API_URL, config, params).catch(err => { return err });
    }

    getListPendingReviewWithPayment = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/fonsnte_admission_request/get_list_pending_review_with_payment?token_auth=${token}`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { 
            token_auth: token
        };

        return await api.httpGet(API_URL, { params, config }).catch(err => { return err });
    }

    sendMail = async (business, processType, title, bodyMail, mailingList, attachmentList) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/send_mail`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            business: business,
            mail:{
                business: business,
                process_type: processType,
                title: title,
                body_mail: bodyMail,
                mailing_list: mailingList,
                attachment_list: attachmentList
            }
        };

        return await api.httpPost(API_URL, config, params).catch(err => { return err });
    }

    getListPaymentsAndAdmissionRequest = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/enroll_payment_fee/get_list_payments_and_admission_request?token_auth=${token}`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { 
            token_auth: token
        };

        return await api.httpGet(API_URL, { params, config }).catch(err => { return err });
    }

    getReceipt = async (token, remote_folder, admission_request_id) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/enroll_payment_fee/get_receipt`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            remote_folder: remote_folder,
            admission_request_id: admission_request_id
        };

        return await api.httpGet(API_URL, { config }, params).catch(err => { return err });
    }

    sendMailResendDocumentCincel = async (params) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/cincel/resend_document`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        return await api.httpPost(API_URL, config, params).catch(err => { return err.response });
    }
    
    getWithoutFolioItbnks = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/without_folio_itbnks`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                "token_auth": token
            }
        };

        const params = {
            token_auth: token
        };

        return await api.httpGet(API_URL, {config}, params);
    }     

    generateSocialContribution = async (token, businessName, documents) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/documents/generate_list_contribution_certificate`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            business_name: businessName,
            documents: documents
        };

        return await api.httpPost(API_URL, {config}, params);
    }

    resendSignatureToMultipleEmails = async (token, listClientId, abbr, typeDocument) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/cincel/resend_document_to_singed`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            list_client_id: listClientId,
            abbreviation: abbr,
            type_document: typeDocument//"social_contribution"
        };

        return await api.httpPost(API_URL, {config}, params);
    }

    sendReminderSignature = async (token, cincel_request_social_contribution_id) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/cincel/resend_document_by_cincel_request_id`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            cincel_request_id: cincel_request_social_contribution_id
        };

        return await api.httpPost(API_URL, {config}, params);
    }

    getRequestsWithRegistrationFee = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_list_of_social_contribution_document_unsigned`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token
        };

        return await api.httpGet(API_URL, {config}, params);
    }

    getLayoutClientItbnksHistory = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_list_of_generated_layout_from_client_itbnks`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                "token_auth": token
            }
        };

        const params = {
            token_auth: token
        };

        return await api.httpGet(API_URL, {config}, params);
    }

    getApplicationsWithRegistrationPaymentSigned = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_list_of_social_contribution_document_signed`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token
        };

        return await api.httpGet(API_URL, {config}, params);
    }

    newFoncabsaClient = async (token, clientIds) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/new_foncabsa_client`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            client_ids: clientIds
        };

        return await api.httpPost(API_URL, config, params);
    }
    getRequiredIn(assistedRequest, clientClassification){
    //   console.log("assisted_request",assistedRequest)
    //   console.log("client_classification",clientClassification)
      if(assistedRequest){
        if (clientClassification == "health_worker")
          return "Inscripción asistida issste"
        else{
          return "Inscripción asistida"
        }
      }else{
        return "solicitud de ingreso fonsnte"
      }
    }

    getCertificateMailHistory = async (token, clientId, abbr) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_history_of_resend_document_to_signed`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            client_id: clientId,
            abbreviation: abbr//"CAS"
        };

        return await api.httpGet(API_URL, {config}, params);
    }

    cancelLayout = async (token, layoutId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/cancel_layout`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            layout_id: layoutId
        };

        return await api.httpPost(API_URL, config, params);
    }

    getUrlCertificateFile = async (token, listDocumentId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/documents/get_signed_url_to_download`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            list_document_id: listDocumentId
        };

        return await api.httpGet(API_URL, {config}, params);
    }

    getUrlToDownloadLayout = async (token, documentName) => {
        
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_url_to_download_layout`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                "token_auth": token
            }
        };

        const params = {
            token_auth: token,
            document_name_list: [documentName]
        };

        return await api.httpPost(API_URL, { config }, params);
    }

    getLastGeneratedFolio = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_last_generated_folio`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                "token_auth": token
            }
        };

        const params = {
            token_auth: token
        };

        return await api.httpGet(API_URL, { config }, params);
    }

    setItbnkFolioConfiguration = async (token, folio) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/set_itbnk_folio_configuration`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            folio_number: folio
        };

        return await api.httpPost(API_URL, config, params);
    }

      
    getListContractSavingsDocumentsUnsigned = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_list_contract_savings_unsigned_document`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token
        };

        return await api.httpGet(API_URL, {config}, params);
    }

    generateSavingsContract = async (token, businessName, documents) => {
        
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/documents/generate_savings_contract`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            business_name: businessName,
            documents: documents
        };

        return await api.httpPost(API_URL, {config}, params);
    }

    getListContractSavingsSignedDocument = async (token) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_list_contract_savings_signed_document`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token
        };

        return await api.httpGet(API_URL, {config}, params);
    }

    get = async (token, admissionId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_request_by_id`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            admission_request_id: admissionId
        };

        return await api.httpGet(API_URL, {config}, params);
    }

    getStructureDocumentsRequired = async (token, admissionId, requeriedIn, remoteFolder) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_structure_documents_required`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            admission_request_id: admissionId,
            requeried_in: requeriedIn,
            remote_folder: remoteFolder
        };

        return await api.httpGet(API_URL, {config}, params);
    }
    getStructureDocumentsAdmissionRequest = async (token, admissionId, requeriedIn) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_or_create_structure_review`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            admission_request_id: admissionId,
            requeried_in: requeriedIn,
        };

        return await api.httpGet(API_URL, {config}, params);
    }

    deleteDocument = async (token, documentId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/delete_document`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            document_id: documentId
        };

        return await api.httpPost(API_URL, {config}, params);
    }

    uploadDocument = async (token, documentList) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/upload_documents_for_registration`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            document_list: documentList
        };

        return await api.httpPost(API_URL, {config}, params);
    }

    updateEnrollmentFeePaymentDate = async (token, admissionId, paymentDate, paymentMethod = "") => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/update_enrollment_fee_payment_date`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            admission_request_id: admissionId,
            enrollment_fee_payment_date: paymentDate,
            enrollment_fee_method: paymentMethod
        };

        return await api.httpPost(API_URL, {config}, params);
    }
    
    getHistoryEmailsBySubject = async (token_auth, table_type, subject, process) => {
        const resource = `/${business}/sent_email_history/index_by_subject?token_auth=${token_auth}&table_type=${table_type}&subject=${subject}&process=${process}`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        // const params = { withCredentials: true, token_auth: token };

        return await api.httpGet(API_URL, { config },{}).catch(err => { return err });
    }

    getParameter = async (token_auth, parameter) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_parameter?token_auth=${token_auth}&parameter=${parameter}`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        return await api.httpGet(API_URL, {config}, {});
    }

    getAndSaveIdentification = async (token_auth, admission_request_id) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_and_save_identification`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token_auth,
            admission_request_id: admission_request_id
        };

        return await api.httpPost(API_URL, {config}, params);
    }

}