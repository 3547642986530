<template>

  <div style="overflow-x: hidden;" class="my-10">
    <Loading :isLoading="isLoading"/>

    <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;"><img src="@/assets/triangle.svg" style="width:30px;" alt=""></div>
      </div>

      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title"> Dirección </label>
        </div>
      </div>
    </div>

    <Address ref="addressRef" :view_class_view="viewClassViewAddress" :validations="rulesValidationsAddress" :buttons_styles="buttons_styles" />

    <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;"><img src="@/assets/triangle.svg" style="width:30px;" alt=""></div>
      </div>

      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title"> Información laboral </label>
        </div>
      </div>
    </div>
    
    <WorkingInformation ref="workInfoRef" :selectBusinessWorkInfo="selectBusinessWorkInfo" :key_toke_storage="keyTokeStorage" :active_job_type_options="activeJobTypeOptions" :active_job_status="activeJobStatus" :view_class_view="viewClassViewWorkInfo" :validations="rulesValidationsWorkInfo" :urlAPI="ccApi" :buttons_styles="buttons_styles" :business="'foncabsa'" />


    <!-- <WorkingInformation ref="workInfoRef" :selectBusinessWorkInfo="selectBusinessWorkInfo" :key_toke_storage="keyTokeStorage" :active_job_type_options="activeJobTypeOptions" :active_job_status="activeJobStatus" :view_class_view="viewClassViewWorkInfo" :validations="rulesValidationsWorkInfo" :urlAPI="ccApi" /> -->

    <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;"><img src="@/assets/triangle.svg" style="width:30px;" alt=""></div>
      </div>

      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title"> Datos de ahorro </label>
        </div>
      </div>
    </div>

    <SavingData ref="savingDataRef" :active_payment_methods="activePaymentMethods" :view_class_view="viewClassViewSavingData" :validations="rulesValidationsSavingData" v-on:saving_registration_attributes="saving_registration_attributes($event)" v-on:bank_account_attributes="bank_account_attributes($event)" :buttons_styles="buttons_styles" />

    <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;"><img src="@/assets/triangle.svg" style="width:30px;" alt=""></div>
      </div>

      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title"> Carga de documentos </label>
        </div>
      </div>
    </div>
    <UploadMultipleFiles ref="uploadFileRefs" :documentsRequired="documentsRequired" v-on:upload-file="getUploadFileResponse($event)" v-on:deleted-file="getDeletedFileResponse($event)" :displayStatusMessage="false" :view_class_view="viewClassViewUploadDocuments"/>
   
    <div class="flex mt-20">
      <input type="button" class="cursor-pointer shadow-md rounded-lg w-full md:w-1/4 mx-auto px-3 py-3 mt-2 btn-next" @click="finishCapture()" value="Finalizar Captura">
    </div>
  </div>
  
</template>

<script>
import { ref, onBeforeMount, onMounted } from 'vue';
import validations from '@/helpers/validations';
import { helpers, requiredIf, minLength, maxLength} from "@vuelidate/validators";
import AdmissionRequest from '@/classes/AdmissionRequest';
import Swal from "sweetalert2";
import { useRouter } from 'vue-router';
import { getTokenDecoden } from "@/helpers/tokenauth";
import moment from "moment";
import Document from '@/classes/Document';

import Loading from '@/components/Loading/VueLoading.vue'
import SwalAlertHelper from '@/helpers/swal-alert'
import { isEqualDate, getCurrentDate, formatIsoDate } from '@/helpers/dates.js';

export default {

  name: 'AssistedEnrollmentStep2Component',

  setup() {
    const _working_information = ref("");
    const ccApi = process.env.VUE_APP_CCAPI;
    const uploadFileRefs = ref(null);
    const selectBusinessWorkInfo = {
      disabled: true,
      default_value: 'Salud pública' 
    };

    let keyTokeStorage = "login";
    
    let documentsRequired = ref([{
      "id": 13,
      "name": "Autorizo de Descuento",
      "optional": false,
      "viewable": true,
      "abbreviation": "AUDSCTOAHORRO",
      "extension": ".pdf|.png|.PNG|.jpeg",
      "requeried_in": "Inscripción asistida",
      "max_documents_required": 1
    },
    {
      "id": 14,
      "name": "CURP",
      "optional": true,
      "viewable": false,
      "abbreviation": "CURP",
      "extension": ".pdf|.png|.PNG|.jpeg",
      "requeried_in": "Inscripción asistida",
      "max_documents_required": 1
    },
    {
      "id": 15,
      "name": "Comprobante de Domicilio",
      "optional": true,
      "viewable": false,
      "abbreviation": "CD",
      "extension": ".pdf|.png|.PNG|.jpeg",
      "requeried_in": "Inscripción asistida",
      "max_documents_required": 1
    },
    {
      "id": 16,
      "name": "Comprobante de Ingresos",
      "optional": true,
      "viewable": false,
      "abbreviation": "CI",
      "extension": ".pdf|.png|.PNG|.jpeg",
      "requeried_in": "Inscripción asistida",
      "max_documents_required": 18
    },
    {
      "id": 17,
      "name": "Estado de Cuenta",
      "optional": true,
      "viewable": false,
      "abbreviation": "EC",
      "extension": ".pdf|.png|.PNG|.jpeg",
      "requeried_in": "Inscripción asistida",
      "max_documents_required": 1
    },
    {
      "id": 18,
      "name": "Constancia de situación Fiscal",
      "optional": true,
      "viewable": false,
      "abbreviation": "RFC",
      "extension": ".pdf|.png|.PNG|.jpeg",
      "requeried_in": "Inscripción asistida",
      "max_documents_required": 1
    },
    {
      "id": 19,
      "name": "Solicitud de Ingreso",
      "optional": true,
      "viewable": false,
      "abbreviation": "SI",
      "extension": ".pdf|.png|.PNG|.jpeg",
      "requeried_in": "Inscripción asistida",
      "max_documents_required": 2
    },
    {
      "id": 20,
      "name": "Recibo pago parte social",
      "optional": false,
      "viewable": true,
      "abbreviation": "RPPSOCIAL",
      "extension": ".pdf|.png|.PNG|.jpeg",
      "requeried_in": "Inscripción asistida",
      "max_documents_required": 1
    }
    ]);

    const activeJobTypeOptions = {
      button_employee_status_working_information: false,
      button_own_businesss_working_information: false,
      button_education_worker_working_information: false,
      button_health_worker_working_information: true,
    };

    const activeJobStatus = {
      button_active_status_working_information: true,
      button_retired_status_working_information: false,
      button_student_status_working_information: false
    };

    const multiApi = process.env.VUE_APP_URLWEB;
    const router = useRouter();
    const identificationRegister = ref(null);

    const rulesValidations = ref({});
    const addressRef = ref(null);
    const workInfoRef = ref(null);
    const savingDataRef = ref(null);

    let admissionRequestObject = ref(null);
    let admissionRequestData = ref(null);

    let accountType = ref("");
    let isLoading = ref(false);
    let documentObjectClass = ref(null);
    let atachedDocuments = ref([]);
    let documentsUploaded = ref("");

    const activePaymentMethods = {
      "button_payroll_saving_data": false,
      "button_card_charge_saving_data": true
    };

    let viewClassViewWorkInfo = {
      classInputs:{
        valid:"border-blue-600",
        no_valid:"border-red-600",
        default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
      },
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600",
      },
      div_container_1 :{
        conatiner : "grid lg:grid-cols-12 grid-cols-1 gap-10  ml-8 my-8",
        div_work_status:"sm:col-span-4",
        div_work_type:"sm:col-span-8",
        choose_button_class:{
          container: "block md:flex",
          button_class : "w-full"
        }
      },
      div_container_2 :{
        conatiner : "grid lg:grid-cols-12 grid-cols-1 gap-10  ml-8 my-8",
        rotation_working:"lg:col-span-3 col-span-12",
        place_working_information:"lg:col-span-9 col-span-12",
        business_name_working:"lg:col-span-9 col-span-6",
        union_section:"lg:col-span-3 col-span-6",
        workplace_working:"lg:col-span-6 col-span-6",
        workplace_name_working:"lg:col-span-4 col-span-6",
        additional_data:"lg:col-span-3 col-span-6"
      },
      div_container_3 :{
        conatiner : "grid lg:grid-cols-12 grid-cols-1 gap-10  ml-8 my-8",
        choose_button_class:{
          container: "block md:flex",
          button_class : "w-full md:w-56"
        },
        retired:"sm:col-span-3",
        publicCharge:"sm:col-span-3"
      },
      div_container_4 :{
        conatiner : "grid lg:grid-cols-12 grid-cols-1 gap-10  ml-8 my-8",
        choose_button_class:{
          container: "block md:flex",
          button_class : "w-full md:w-56"
        },
        retired:"sm:col-span-3",
        public_office_owner:"sm:col-span-3",
        public_office_date_start:"sm:col-span-3",
        public_office_date_end:"sm:col-span-3"
      },
      div_container_5 :{
        conatiner : "grid lg:grid-cols-12 grid-cols-1 gap-10  ml-8 my-8",
        phone_working_information:"sm:col-span-4",
        phone_extension:"sm:col-span-4"
      },
      div_container_6 :{
        conatiner : "grid lg:grid-cols-3 grid-cols-1 gap-10  ml-8 my-8",
        place_working_information:"lg:col-span-3 col-span-12",
      },
      
    }

    let rulesValidationsAddress = {
      street: {
        required:helpers.withMessage("El campo es requerido.", requiredIf(true)),
        isAlphaNumeric:helpers.withMessage(`El campo no admite caracteres especiales.`,(data,index)=>index.street != ""? !new RegExp(/[^0-9a-zA-ZÀ-ÿ\s-#]+/).test(index.street): true),
        minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres", minLength(2)),
        maxLength: helpers.withMessage("El campo debe contener máximo 100 caracteres", maxLength(100)),
        we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.street != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.street): true),
        street_one_and_street_two_equal_street: helpers.withMessage(`El valor de: "Calle" no pueden ser igual a "Entre Calle" o "Y Calle"`, (data,index)=> ![index.between_street_one,index.between_street_two].includes(index.street))
      },
      suburb: {
        required:helpers.withMessage("El campo es requerido.", requiredIf(true))
      },
      ext_number: {
        required:helpers.withMessage("El campo es requerido.", requiredIf(true)),
        minLength: helpers.withMessage("El campo debe contener mínimo 1 caracteres", minLength(1)),
        maxLength: helpers.withMessage("El campo debe contener máximo 5 caracteres", maxLength(5)),
        onlyNumber: helpers.withMessage("El campo solo acepta números.", (data,index)=> (new RegExp('^[0-9]+$')).test(index.ext_number))
      },
      int_number: {
        minLength: helpers.withMessage("El campo debe contener mínimo 1 caracteres", minLength(1)),
        maxLength: helpers.withMessage("El campo debe contener máximo 5 caracteres", maxLength(5)),
        onlyNumberAndLetter: helpers.withMessage("El campo solo acepta letras y números.", (data,index)=> index.int_number == "" ? true : (new RegExp('^[0-9a-zA-Z]+$')).test(index.int_number))
      },
      postal_code: {
        required:helpers.withMessage("El campo es requerido.", requiredIf(true)),
        minLength: helpers.withMessage("El campo debe contener mínimo 5 caracteres", minLength(5)),
        maxLength: helpers.withMessage("El campo debe contener máximo 5 caracteres", maxLength(5)),
        onlyNumber: helpers.withMessage("El campo solo acepta números.", (data,index)=> {
          if(index.postal_code.length != 5){
            index.suburb = ""
          }
          return (new RegExp('^[0-9]+$')).test(index.postal_code)
        }),
        $autoDirty: true
      },
      between_street_one: {
        required:helpers.withMessage("El campo es requerido.",requiredIf(true)),
        isAlphaNumeric:helpers.withMessage(`El campo no admite caracteres especiales.`,(data,index)=>index.street != ""? !new RegExp(/[^0-9a-zA-ZÀ-ÿ\s-#]+/).test(index.between_street_one): true),
        minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres", minLength(2)),
        maxLength: helpers.withMessage("El campo debe contener máximo 100 caracteres", maxLength(100)),
        we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.between_street_one != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.between_street_one): true),
        street_one_and_street_two_equal: helpers.withMessage(`El valor de: "Entre Calle" y el valor: "Y Calle" no pueden ser iguales`, (data,index)=> index.between_street_one != index.between_street_two)
      },
      between_street_two:{
        required:helpers.withMessage("El campo es requerido.",requiredIf(true)),
        isAlphaNumeric:helpers.withMessage(`El campo no admite caracteres especiales.`,(data,index)=>index.between_street_two != ""? !new RegExp(/[^0-9a-zA-ZÀ-ÿ\s-#]+/).test(index.between_street_two): true),
        minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres", minLength(2)),
        maxLength: helpers.withMessage("El campo debe contener máximo 100 caracteres", maxLength(100)),
        we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.between_street_two != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.between_street_two): true),
        street_one_and_street_two_equal: helpers.withMessage(`El valor de: "Entre Calle" y el valor: "Y Calle" no pueden ser iguales`, (data,index)=> index.between_street_one != index.between_street_two)
      }
    };

    let viewClassViewAddress = {
      classInputs:{
        valid:"border-blue-600",
        no_valid:"border-red-600",
        default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
      },
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600",
      },
      div_container_1 : {
        container:"grid grid-cols-1 sm:grid-cols-3 sm:gap-10 ml-8 mt-8",
        input_class:"",
        div_container_neightboorhood:{
          container:"mt-5 sm:mt-0"
        },
      },
      div_container_2 : {
        container:"grid grid-cols-1 sm:grid-cols-3 sm:gap-10 mt-5 ml-8",
        state:{
          label : "label-input w-full block tracking-wide mb-2 mt-5",
          label_information: "label-input w-full block tracking-wide mb-2 text-2xl mt-5",
        },
        municipality:{
          label : "label-input w-full block tracking-wide mb-2 mt-5",
          label_information: "label-input w-full block tracking-wide mb-2 text-2xl mt-5",
        },
        city_address:{
          label : "label-input w-full block tracking-wide mb-2 mt-5",
          label_information: "label-input w-full block tracking-wide mb-2 text-2xl mt-5",
        }
      },
      div_container_3 : {
        container:"grid grid-cols-1 sm:grid-cols-4 sm:gap-10 ml-8",
        div_container_street:{
          container:"sm:col-span-2 mt-5"
        },
        div_container_exterior_street:{
          container:"mt-5"
        },
        div_container_interior_street:{
          container:"mt-5"
        }
      },
      div_container_4 : {
        container:"grid grid-cols-1 sm:grid-cols-4 sm:gap-10 ml-8 mb-8",
        div_container_between_street_address:{
          container:"mt-5"
        },
        div_and_street_address:{
          container:"mt-5"
        }
      }
    };

    let viewClassViewUploadDocuments = {
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600",
      },
      div_container_section:{
        container:"w-full",
        label: "text-4xl",
        form: "w-full mt-5"
      }
    }


    let rulesValidationsWorkInfo = {
          work: {
              workStatus: {
                require : helpers.withMessage("El campo es requerido", requiredIf(function(value,index){
                  return false;
                }))
              },
              typeOfWork: {
                require : helpers.withMessage("El campo es requerido", requiredIf(function(value,index){
                  if(index.typeOfWork == "Negocio Propio")
                  {
                    document.getElementById("button_payroll_saving_data").style.display = 'none'; 
                  }
                  if(_working_information.value == true && index.typeOfWork != "Negocio Propio")
                  {
                    document.getElementById("button_payroll_saving_data").style.display = 'block'; 
                  }
                  return workInfoRef.value.laborInformation.work.workStatus;
                }))
              },
              workName: {
                require : helpers.withMessage("El campo es requerido", requiredIf(function(){
                  return (workInfoRef.value.laborInformation.work.workStatus == true && workInfoRef.value.laborInformation.work.typeOfWork == "Negocio Propio")? true : false;
                })),
                minLength: helpers.withMessage("El campo debe contener mínimo 5 caracteres", minLength(5)),
                maxLength: helpers.withMessage("El campo debe contener máximo 50 caracteres", maxLength(50)),
              },
              freeWorkCenterName: {
                require : helpers.withMessage("El campo es requerido", requiredIf(function(){
                  return (workInfoRef.value.laborInformation.work.typeOfWork == "Trabajador de la educacion" &&
                    workInfoRef.value.laborInformation.work.workStatus == true) ?
                  true :
                  false;
                })),
                minLength: helpers.withMessage("El campo debe contener mínimo 5 caracteres", minLength(5)),
                maxLength: helpers.withMessage("El campo debe contener máximo 50 caracteres", maxLength(50)),
              },
              workCenter: {
                  businessLine: {
                    require : helpers.withMessage("El campo es requerido ", requiredIf(function(value){
                      if (
                        (workInfoRef.value.laborInformation.work.workStatus == true &&
                          workInfoRef.value.laborInformation.work.typeOfWork == "Empleado") ||
                        (workInfoRef.value.laborInformation.work.workStatus &&
                          workInfoRef.value.laborInformation.work.typeOfWork == "Negocio Propio") ||
                        (workInfoRef.value.laborInformation.work.workStatus &&
                          workInfoRef.value.laborInformation.work.typeOfWork == "Trabajador de la salud")
                      ) {
                        return true;
                      } else if (workInfoRef.value.laborInformation.work.workStatus == false ) {
                        return true;
                      }

                      return false;
                    }))
                  },
                  workCenter: {
                    require : helpers.withMessage("El campo es requerido", requiredIf(function(value, index) {
                      return false;
                    }))
                  },
                  workCenterName: {
                    require : helpers.withMessage("El campo es requerido", requiredIf(function() {

                      return (workInfoRef.value.laborInformation.work.workCenter.workCenterName == "OTRO" &&
                        workInfoRef.value.laborInformation.work.typeOfWork == "Empleado" &&
                        workInfoRef.value.laborInformation.work.workStatus == true) ?
                        true :
                        false;
                    })),
                    minLength: helpers.withMessage("El campo nombre del centro de trabajo tiene que tener como mínimo 5 caracteres", minLength(5)),
                    maxLength: helpers.withMessage("El campo nombre del centro de trabajo tiene que tener como máximo 50 caracteres", maxLength(50)),
                  },
                  unionSection: {
                    require : helpers.withMessage("El campo es requerido", requiredIf(function(value, index) {
                      return false;
                    })),
                  },
                  additional_data:{
                    require:helpers.withMessage("El campo es requerido ", requiredIf( (value,index) => {
                      return false;
                    })),
                  }
              },
          },
          telephone: {
            number: {
              required: helpers.withMessage("El campo teléfono es requerido.",requiredIf(function(value) {
                return workInfoRef.value.laborInformation.work.workStatus;
              })),
              minLength: helpers.withMessage( "El campo teléfono debe contener mínimo 10 dígitos",  function(value){ 
                if(!workInfoRef.value.laborInformation.work.workStatus && workInfoRef.value.laborInformation.work.workStatus == null){
                  return true;
                }

                value = value.toString().replace(" ", "");
                value = value.toString().replace("-", "");

                return (value.toString().length < 10)? false : true;
              }),
              maxLength: helpers.withMessage("El campo teléfono debe contener máximo 10 dígitos",  function(value){ 
                value = value.toString().replace(" ", "");
                value = value.toString().replace("-", "");
                return (value.toString().length > 10)? false : true;
              }),

            },
            lada: {
              minLength: helpers.withMessage("El campo extensión debe contener mínimo 1 dígitos", minLength(1)),
              maxLength: helpers.withMessage("El campo extensión debe contener máximo 4 dígitos", maxLength(4)),
              numericValidator:helpers.withMessage("El campo extensión solo puede contener números.",(value)=> {
                if(value!=""){
                  return new RegExp(/^[0-9]+$/).test(value)
                }
                else return true;
              })
            },
          },
        publicCharge: {
          questionOne: {
            required: requiredIf(function() {
              return false;
            })
          },
          questionTwo: {
            required: helpers.withMessage("El campo cargo público ha desempeñado es requerido. ",requiredIf(function(value, e) {
              return (!e.questionTwo)? false: true;
            })),
            minLength: helpers.withMessage("El campo cargo público debe contener mínimo 1 caracteres",minLength(1)) ,
            maxLength: helpers.withMessage("El campo cargo público debe contener máximo 50 caracteres",maxLength(50)) ,
            alphaNumAndDotValidator: helpers.withMessage("El campo cargo público no admite caracteres especiales. ",(value)=>{
               if(value!="")
              return new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/).test(value)
              else return true;
            }),
            $autoDirty: true
          },
          startDate: {
            required: helpers.withMessage("El campo fecha inicio es requerido.",requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;
            })),
            dateNotAllowed: helpers.withMessage('La fecha debe ser menor o igual a la fecha actual', function(value, e){
              if(!e.questionOne) return true;
              var currentDate = moment().startOf('day');
              var date = moment(formatIsoDate(value,'-'), 'YYYY-MM-DD').startOf('day');
              
              return !currentDate.isBefore(date);
            }),
            isValid: helpers.withMessage("El campo debe tener una fecha menor a la fecha final.",function(value, e){
              if(!e.questionOne){
                return true;
              }

              var dateSelected = moment(formatIsoDate(value,'-'), 'YYYY-MM-DD').startOf('day');
              var dateEnd = moment(formatIsoDate(e.endDate,'-'), 'YYYY-MM-DD').startOf('day');
              var isAfter = dateSelected.isAfter(dateEnd)
              return !isAfter;
            }),
            equal: helpers.withMessage("El campo fecha inicio no puede ser igual a la fecha final.", function(value, e) {
              if(!e.questionOne || e.endDate == ""){
                return true;
              }
              
              return !(value == e.endDate);
            }),
            $autoDirty: true
          },
          endDate: {
            required: helpers.withMessage("El campo fecha final es requerido.", requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;
            })),
            dateNotAllowed: helpers.withMessage('La fecha debe ser menor o igual a la fecha actual', function(value, e){
              if(!e.questionOne) return true;
              var currentDate = moment().startOf('day');
              var date = moment(formatIsoDate(value,'-'), 'YYYY-MM-DD').startOf('day');
              return !currentDate.isBefore(date);
            }),
            isValid: helpers.withMessage("El campo debe tener una fecha mayor a la fecha inicial.",function(value, e){
              if(!e.questionOne){
                return true;
              }

              var dateSelected = moment(formatIsoDate(value,'-'), 'YYYY-MM-DD').startOf('day');
              var dateEnd = moment(formatIsoDate(e.startDate,'-'), 'YYYY-MM-DD').startOf('day');
              var isBefore = dateSelected.isBefore(dateEnd)
              return !isBefore;
            }),
            $autoDirty: true
          },
        },
        publicChargeFamily: {
          questionOne: {
            required: requiredIf(function() {
              return false;
            })
          },
          questionTwo: {
            required: helpers.withMessage("El campo cargo público ha desempeñado es requerido.",requiredIf(function() {
              return (!workInfoRef.value.laborInformation.publicChargeFamily.questionTwo)? false: true;
            })),
            minLength: helpers.withMessage("El campo cargo público debe contener mínimo 1 caracteres",minLength(1)),
            maxLength: helpers.withMessage("El campo cargo público debe contener máximo 50 caracteres",maxLength(50)),
            alphaNumAndDotValidator:helpers.withMessage("El campo cargo público no admite caracteres especiales. ",(value)=>{
              if(value!="")
              return new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/).test(value)
              else return true;
            }),
            $autoDirty: true
          },
          startDate: {
            required:helpers.withMessage("El campo fecha inicio es requerida.", requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;
            })),
            dateNotAllowed: helpers.withMessage('La fecha debe ser menor o igual a la fecha actual', function(value, e){
              if(!e.questionOne) return true;
              var currentDate = moment().startOf('day');
              var date = moment(formatIsoDate(value,'-'), 'YYYY-MM-DD').startOf('day');
              return !currentDate.isBefore(date);
            }),
            isValid: helpers.withMessage("El campo debe tener una fecha menor a la fecha final.", function(value, e){
              if(!e.questionOne){
                return true;
              }

              var dateSelected = moment(formatIsoDate(value,'-'), 'YYYY-MM-DD').startOf('day');
              var dateEnd = moment(formatIsoDate(e.endDate,'-'), 'YYYY-MM-DD').startOf('day');
              var isAfter = dateSelected.isAfter(dateEnd)
              return !isAfter;
            }),
            equal: helpers.withMessage("El campo fecha inicio no puede ser igual a la fecha final.", function(value, e) {
              if(!e.questionOne || e.endDate == ""){
                return true;
              }
              return !(value == e.endDate);
            }),
            $autoDirty: true
          },
          endDate: {
            required: helpers.withMessage("El campo fecha final es requerida.", requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;

            })),
            dateNotAllowed: helpers.withMessage('La fecha debe ser menor o igual a la fecha actual', function(value, e){
              if(!e.questionOne) return true;
              var currentDate = moment().startOf('day');
              var date = moment(formatIsoDate(value,'-'), 'YYYY-MM-DD').startOf('day');
              return !currentDate.isBefore(date);
            }),
            isValid: helpers.withMessage("El campo debe tener una fecha mayor a la fecha inicial.",function(value, e){
              if(!e.questionOne){
                return true;
              }

              var dateSelected = moment(formatIsoDate(value,'-'), 'YYYY-MM-DD').startOf('day');
              var dateEnd = moment(formatIsoDate(e.startDate,'-'), 'YYYY-MM-DD').startOf('day');
              var isBefore = dateSelected.isBefore(dateEnd)
              return !isBefore;
            }),
            $autoDirty: true
          },
        },
    }

    function convertDate(date){
      let newDate = date.split('-')
      return new Date(`${newDate[2]}/${newDate[1]}/${newDate[0]}`);
    }

    const workingInformation = ref(null);

    let rulesValidationsSavingData = {
      saving_registration_attributes:{
        amount:{
          required: helpers.withMessage(()=> `El campo importe ${admissionRequestObject.value.data.saving_registration_attributes.payment_frecuency} de ahorro es requerido`, function (value){
            return true && (formatNumber(value)>=100)
          }),
          import: helpers.withMessage(()=> ` El campo importe ${admissionRequestObject.value.data.saving_registration_attributes.payment_frecuency} tiene que ser en multiplos de $50`, function (value) {
            return (formatNumber(value)%50) == 0 && formatNumber(value)>0?true:false;
          }),
          minValue: helpers.withMessage(()=>  `El campo importe ${admissionRequestObject.value.data.saving_registration_attributes.payment_frecuency} de ahorro debe ser mayor o igual a 100`, function(value) {
            return (formatNumber(value)>=100);
          }),
        },
        payment_method:{
          required:helpers.withMessage("Seleccione un método de pago.",  requiredIf(function(){
            return savingDataRef.value.saving_registration_attributes.payment_method == ''
          })),
        },
        payment_frecuency:{
          required:helpers.withMessage("Seleccione un periodo de ahorro.",  requiredIf(true)),
        },
      },
      bank_account_attributes:{
        0:{
          bank_name:{
              required:helpers.withMessage("El campo banco es requerido.",  requiredIf(true))
          },
          account_type:{
            required: helpers.withMessage("Seleccione el tipo de cuenta.",  requiredIf(true))
          },
          account_number:{
            required:helpers.withMessage(() => { 
              console.log("account:", admissionRequestObject.value.data.bank_account_attributes[0])
              return (admissionRequestObject.value.data.bank_account_attributes[0].account_type == "numero de tarjeta")? `El campo número de tarjeta es requerido`: `El campo cuenta clabe es requerido`; 
            }, requiredIf(true)), 
            minValue:
            helpers.withMessage(   
              ()=>{
                
                if(admissionRequestObject.value.data.bank_account_attributes[0].account_type == "numero de tarjeta" && admissionRequestObject.value.data.bank_account_attributes[0].bank_name == "AMERICAN EXPRESS"){
                  return "El campo debe contener 15 caracteres."
                }
              else{
                if(admissionRequestObject.value.data.bank_account_attributes[0].account_type == "cuenta clabe"){
                  return "El campo debe contener 18 caracteres.";
                }
                  else{
                    return "El campo debe contener 16 caracteres.";
                  }
                } 
              },
              (value)=>{
                if(admissionRequestObject.value.data.bank_account_attributes[0].account_type == "numero de tarjeta" && admissionRequestObject.value.data.bank_account_attributes[0].bank_name == "AMERICAN EXPRESS"){
                  return value.toString().length==18;
                }
                else{
                  if(admissionRequestObject.value.data.bank_account_attributes[0].account_type =="cuenta clabe"){
                    return value.toString().length==18;
                  }
                  else{
                    if( admissionRequestObject.value.data.bank_account_attributes[0].account_type == "numero de tarjeta"){
                      return value.toString().length==19;
                    }else{
                      return true;
                    }
                  }
                } 
              }
            ),      
          },
          expiration_year:{
             required: helpers.withMessage("El campo año de vencimiento es requerido.",requiredIf(function(value, e){
                return e.account_type == "Numero de tarjeta";
              })),
              expirationDate: helpers.withMessage("La tarjeta ha expirado", function(value, e){
                if(e.expiration_year != "" && e.expiration_month != "" && e.account_type == "Numero de tarjeta"){
                  let cardYear=  parseInt(e.expiration_year);
                  let cardMonth=  parseInt(e.expiration_month);

                  let currentYear = parseInt(moment().format('YY'));
                  let currentMonth = parseInt(moment().format('MM'));
                  
                  if(cardYear <= currentYear && cardMonth <= currentMonth){
                    return false;
                  }

                }
                
                return true;
              }),
          },
          expiration_month:{
             required:helpers.withMessage("El campo mes de vencimiento es requerido.",requiredIf(function(value, e){
                return e.account_type == "Numero de tarjeta";
              })),
              expirationDate: function(value, e){
                if(e.expiration_year != "" && e.expiration_month != "" && e.account_type == "Numero de tarjeta"){
                  let cardYear=  parseInt(e.expiration_year);
                  let cardMonth=  parseInt(e.expiration_month);

                  let currentYear = parseInt(moment().format('YY'));
                  let currentMonth = parseInt(moment().format('MM'));
                  
                  if(cardYear <= currentYear && cardMonth <= currentMonth){
                    return false;
                  }

                }
                
                return true;
              },
          }
        },
      }
    }

    const  formatNumber = (value) => {
      var regex = /^[,.]+$/;
      if (regex.test(value) != true) {
        value = value.replace(
          /[^0-9.]+/g,
          ""
        );
      }
      return value;
    }

    let viewClassViewSavingData = {
      classInputs:{
        valid:"border-blue-600",
        no_valid:"border-red-600",
        default:"py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
      },
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600",
      },
      div_container_section:{
        container:"w-full",
        label: "text-4xl",
        form: "grid md:grid-cols-3 grid-cols-1 gap-10  ml-8 my-8"
      }
    }

    onBeforeMount(async () => {
      isLoading.value = true;
      loadInfo();
      await getValidationsRules();

      documentObjectClass.value = new Document();
      admissionRequestObject.value = new AdmissionRequest();

      admissionRequestObject.value.data = admissionRequestData.value;

      await getDocumentsRequired();
      getDocuments();
    });

    onMounted(()=>{
      isLoading.value = false;
    });

    async function getValidationsRules() {
      const response = await validations.getStructure();
      rulesValidations.value = response.data.validations;
    };

    async function getDocumentsRequired() {
      try {
        const response = await documentObjectClass.value.getDocumentsRequired(getToken(),'Inscripción asistida issste').then(resp => resp.data.response.data).catch(err => []);
        documentsRequired.value = documentObjectClass.value.createDocumentsRequiredArray(response);
      } catch (error) {
        console.log("Error", error)
      }
    }

    async function finishCapture(){
      
      isLoading.value = true;
      const AddressRefs = addressRef.value.execute_validation();
      const WorkingInformationRefs = workInfoRef.value.execute_validation();
      const SavingDataRefs = savingDataRef.value.execute_validation();
      
      if(WorkingInformationRefs && SavingDataRefs && AddressRefs){
        if (!documentsUploaded.value) {

          let filesNotUploadedText = ""; 
          const files = uploadFileRefs.value.getRequiredFileStatus();

          if(files.length>0 && files.map(file => file.optional).includes(false)){
            files.forEach((data, key) => {
              if(!data.optional && !data.documentAttached){
                filesNotUploadedText += `${data.name}<br>`;
              }
            });

            Swal.fire({
              title: "Aviso",
              html: "Favor de adjuntar los documentos: <br>"+filesNotUploadedText,
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            
            isLoading.value = false;
            SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_upload_file_require');
            SwalAlertHelper.setIdAttributeToButtons('button_upload_file_ok', '');
            return;
          }
        }

        var email = ""
        for (let index = 0; index < Object.keys(admissionRequestObject.value.data.contact_info_attributes).length; index++) {
          let contact = admissionRequestObject.value.data.contact_info_attributes[index.toString()]
          if(contact.contact_type == 'correo'){
            email = contact.value
          }
        }  

        admissionRequestObject.value.data.token_auth = sessionStorage.getItem("login");
        setWorkInfoData(WorkingInformationRefs);
        setAddressData(AddressRefs);
      
        let titleGender = admissionRequestObject.value.data.gender == "hombre" ? "Bienvenido" : "Bienvenida";
        admissionRequestObject.value.data.title = titleGender + " a FONCABSA";
        admissionRequestObject.value.data.message = titleGender + " " + admissionRequestObject.value.data.name + " " + admissionRequestObject.value.data.middle_name + " " + admissionRequestObject.value.data.paternal_name + " " + admissionRequestObject.value.data.maternal_name + ` le informamos que su cuenta fue creada con una contraseña provisional la cual es la siguiente ---, le invitamos a acceder a la plataforma de FONCABSA para actualizar su contraseña en el menú Cambiar contraseña, en el siguiente enlace <a href="${multiApi}/login" target="_black">${multiApi}/login</a>. <br><br>Usuario: ${email}<br>Contraseña: ---`;
        
        const response = await admissionRequestObject.value.save(admissionRequestObject.value.data);
      
        const documents = getDocuments();
        const documentsWithAdmissionRequestId = setAdmissionRequestIdToAtacchedFiles(response.data.response.admission_request.id, documents);
        await documentObjectClass.value.save(sessionStorage.getItem('login'), documentsWithAdmissionRequestId);
      

        isLoading.value = false;
        showSavedAdmissionRequestMessage(); 
      }else{
        
        isLoading.value = false;

        let messageHTMLvalue = "";

        if(!AddressRefs){
          messageHTMLvalue = messageHTMLvalue+"Favor de capturar la sección de dirección"+"<br>";
        }

        if(!WorkingInformationRefs){
          messageHTMLvalue = messageHTMLvalue+"Favor de capturar la sección de información laboral"+"<br>";
        }
            
        if(!SavingDataRefs){
          messageHTMLvalue = messageHTMLvalue+"Favor de capturar la sección datos de ahorro"+"<br>";
        }
            
        Swal.fire({
          title: "Aviso",
          html: "<strong>Favor de capturar correctamente la(s) seccion(es):</strong><br>" + messageHTMLvalue,
          icon: "error",
        });
      }
    }

    const getToken = () => {
      return sessionStorage.getItem('login');
    }

    function setAdmissionRequestIdToAtacchedFiles(admissionRequestId, files) {
      let documents = files.map(item => {
        let temp = Object.assign({}, item);
        temp.admission_request_id = admissionRequestId;
        return temp;
      });

      return documents;
    }


    function getDocuments() {
      let files = [];
      let identificationFiles = JSON.parse(sessionStorage.getItem("identificationFiles"));
      let atacchedFiles = atachedDocuments.value;

      return files.concat(identificationFiles, atacchedFiles);
    }

    function showSavedAdmissionRequestMessage() {
      Swal.fire({
        html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">La solicitud se guardó correctamente</p>`,
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        icon: "success",
        confirmButtonColor: '#FEB72B'
      }).then((result) => {
        if (result.value) {
          redirecTo();
        }
      });
    }

    function redirecTo(){
     var user = getTokenDecoden();
      if (user != null) {
        switch (user.obj['role'] ) {
          case "supervisor":
            router.push({path: "/supervisor/principal"})
            break;
          case "administrator":
            router.push({path: "/admin/principal"})
            break;
          case "loan_analyst":
            router.push({path: "/analyst/principal"})
          break;
          case "member_analyst":
            router.push({path: "/analyst/principal"})
          break;
          case "issste_enroller":
            router.push({path: "/sinaptissste-enroller/principal"})
          break;
          case "enroller":
            Swal.fire({
            title: "Solicitud registrada con éxito.",
            text: "¿Deseas capturar otra solicitud?",
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "SI",
            cancelButtonText: "NO",
            confirmButtonColor: '#FEB72B',
            cancelButtonColor: '#FEB72B'
            }).then((result) => {
              if (result.value) {
                router.push({ path:`/${accountType.value}/captura-asistida/sinaptissste/seccion/uno`});
              }else{
                router.push({path: "/adviser/principal"});
              }
            });
          break;
        }
      }
    }

    function setAddressData(data){
      admissionRequestObject.value.data.adress_attributes.street = data.street.toString().toUpperCase();
      admissionRequestObject.value.data.adress_attributes.suburb = data.suburb.toString().toUpperCase();
      admissionRequestObject.value.data.adress_attributes.city = (data.city == "" || data.city == undefined) ? '-----' : data.city;
      admissionRequestObject.value.data.adress_attributes.state = data.state;
      admissionRequestObject.value.data.adress_attributes.country = data.country.toString().toUpperCase();
      admissionRequestObject.value.data.adress_attributes.ext_number = data.ext_number;
      admissionRequestObject.value.data.adress_attributes.int_number = data.int_number;

      admissionRequestObject.value.data.adress_attributes.postal_code = data.postal_code.toString().toUpperCase();
      admissionRequestObject.value.data.adress_attributes.municipality = data.municipality;
      admissionRequestObject.value.data.adress_attributes.between_street_one = (data.between_street_one == undefined || data.between_street_one.toString() == "")? "-----" : data.between_street_one.toString().toUpperCase();
      admissionRequestObject.value.data.adress_attributes.between_street_two = (data.between_street_two == undefined || data.between_street_two.toString() == "")? "-----" : data.between_street_two.toString().toUpperCase();
      admissionRequestObject.value.data.adress_attributes.status = "activo";

      admissionRequestObject.value.data.street = data.street.toString().toUpperCase();
      admissionRequestObject.value.data.suburb = data.suburb.toString().toUpperCase();
      admissionRequestObject.value.data.city = data.city;
      admissionRequestObject.value.data.state = data.state;
      admissionRequestObject.value.data.country = data.country.toString().toUpperCase();
      admissionRequestObject.value.data.ext_number = data.ext_number.toString().toUpperCase();
      admissionRequestObject.value.data.int_number = data.int_number.toString().toUpperCase();

      admissionRequestObject.value.data.postal_code = data.postal_code.toString();
      admissionRequestObject.value.data.municipality = data.municipality;
      admissionRequestObject.value.data.between_street_one = data.between_street_one.toString().toUpperCase();
      admissionRequestObject.value.data.between_street_two = data.between_street_two.toString().toUpperCase();
    }

    function setWorkInfoData(data){
      admissionRequestObject.value.data.work_info_attributes.supervisor = "-----";
      admissionRequestObject.value.data.work_info_attributes.employee_number = "-----";
      admissionRequestObject.value.data.work_info_attributes.category = "-----";
      admissionRequestObject.value.data.work_info_attributes.active_since = moment().format('YYYY-MM-DD');
      admissionRequestObject.value.data.work_info_attributes.status = 'activo';
      admissionRequestObject.value.data.work_info_attributes.laboral_status = (data.work_info.work.workStatus == true) ? 'ACTIVO': (data.work_info.work.workStatus==null)? 'ESTUDIANTE':'JUBILADO';
      admissionRequestObject.value.data.work_info_attributes.occupation = data.work_info.work.workCenter.businessLine == "" ? "-----" : data.work_info.work.workCenter.businessLine;

      admissionRequestObject.value.data.workplace_attributes.center_key = "-----";
      admissionRequestObject.value.data.workplace_attributes.employer = (data.work_info.work.workCenter.unionSection == 'other')? "-----": data.work_info.work.workCenter.unionSection;
      admissionRequestObject.value.data.work_info_attributes.business_name = data.work_info.work.workName == "" ? "-----" : data.work_info.work.workName;
      
      admissionRequestObject.value.data.workplace_attributes.name = data.work_info.work.workCenter.workCenter == "" ? "-----" : addUpperCase(data.work_info.work.workCenter.workCenter);
      admissionRequestObject.value.data.workplace_attributes.city = data.work_address_info.city;
      admissionRequestObject.value.data.workplace_attributes.ext_number = data.work_address_info.ext_number;
      admissionRequestObject.value.data.workplace_attributes.int_number = data.work_address_info.int_number;
      admissionRequestObject.value.data.workplace_attributes.postal_code = data.work_address_info.postal_code;
      admissionRequestObject.value.data.workplace_attributes.municipality = data.work_address_info.municipality;
      admissionRequestObject.value.data.workplace_attributes.country = data.work_address_info.country;
      admissionRequestObject.value.data.workplace_attributes.between_street_one = data.work_address_info.between_street_one;
      admissionRequestObject.value.data.workplace_attributes.between_street_two = data.work_address_info.between_street_two;
      admissionRequestObject.value.data.workplace_attributes.street = data.work_address_info.street;
      admissionRequestObject.value.data.workplace_attributes.suburb = data.work_address_info.suburb;
      admissionRequestObject.value.data.workplace_attributes.state = data.work_address_info.state;

      admissionRequestObject.value.data.workplace_attributes.adress_attributes.name = data.work_info.work.workCenter.workCenter == "" ? "-----" : addUpperCase(data.work_info.work.workCenter.workCenter);
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.status = "activo";
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.city = data.work_address_info.city == "" ? "-----" : data.work_address_info.city ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.ext_number = data.work_address_info.ext_number == "" ? "-----" : data.work_address_info.ext_number ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.int_number = data.work_address_info.int_number == "" ? "-----" : data.work_address_info.int_number ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.postal_code = data.work_address_info.postal_code == "" ? "-----" : data.work_address_info.postal_code ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.municipality = data.work_address_info.municipality == "" ? "-----" : data.work_address_info.municipality ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.country = data.work_address_info.country == "" ? "-----" : data.work_address_info.country ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.between_street_one = data.work_address_info.between_street_one == "" ? "-----" : data.work_address_info.between_street_one ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.between_street_two = data.work_address_info.between_street_two == "" ? "-----" : data.work_address_info.between_street_two ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.street = data.work_address_info.street == "" ? "-----" : data.work_address_info.street ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.suburb = data.work_address_info.suburb == "" ? "-----" : data.work_address_info.suburb ;
      admissionRequestObject.value.data.workplace_attributes.adress_attributes.state = data.work_address_info.state == "" ? "-----" : data.work_address_info.state ;
      
      admissionRequestObject.value.data.work_info_attributes.principal_occupation = data.work_info.work.typeOfWork;
      let dateWork = {
        laboral_status:admissionRequestObject.value.data.work_info_attributes.laboral_status, //Activo o jubilado
        principal_occupation:admissionRequestObject.value.data.work_info_attributes.principal_occupation,
        workCenter: data.work_info.work.workCenter.workCenter == "" ? "-----" : addUpperCase(data.work_info.work.workCenter.workCenter)
      };

      savingDataRef.value.statusEmployment(dateWork);
      admissionRequestObject.value.data.client_classification = "health_worker";   
            
      // Atributos de contacto (Telephone)
      admissionRequestObject.value.data.workplace_attributes.contact_info_attributes[0].value = removeMask(data.work_info.telephone.number);
      admissionRequestObject.value.data.workplace_attributes.contact_info_attributes[0].extension = data.work_info.telephone.lada;
      admissionRequestObject.value.data.workplace_attributes.contact_info_attributes[0].status = 'activo';

      // Atributos de cargo publico (Titular) 
      const {questionTwo:publicChargeQuestionTwo, questionOne:publicChargeQuestionOne, endDate: publicChargeQuestionEndDate, startDate: publicChargeQuestionStartDate} = data.work_info.publicCharge;

      admissionRequestObject.value.data.public_office_attributes[0].relationship = "titular";
      admissionRequestObject.value.data.public_office_attributes[0].positions_name = publicChargeQuestionTwo;
      admissionRequestObject.value.data.public_office_attributes[0].has_had_public_positions = publicChargeQuestionOne? true: false;
      admissionRequestObject.value.data.public_office_attributes[0].end_date_public_positions = publicChargeQuestionEndDate;
      admissionRequestObject.value.data.public_office_attributes[0].start_date_public_positions = publicChargeQuestionStartDate;
      
      // Atributos de cargo publico (Familiar)
      const {questionTwo:publicChargeFamilyQuestionTwo, questionOne:publicChargeFamilyQuestionOne, endDate: publicChargeFamilyQuestionEndDate, startDate: publicChargeFamilyQuestionStartDate} = data.work_info.publicChargeFamily;

      admissionRequestObject.value.data.public_office_attributes[1].relationship = "familiar";
      admissionRequestObject.value.data.public_office_attributes[1].positions_name = publicChargeFamilyQuestionTwo;
      admissionRequestObject.value.data.public_office_attributes[1].has_had_public_positions = publicChargeFamilyQuestionOne? true: false;
      admissionRequestObject.value.data.public_office_attributes[1].end_date_public_positions = publicChargeFamilyQuestionEndDate;
      admissionRequestObject.value.data.public_office_attributes[1].start_date_public_positions = publicChargeFamilyQuestionStartDate;

    }

    const addUpperCase = (value) => {
      if(value != "" && value != undefined){
          value = value.toString().toUpperCase();
      }
      return value
    }

    const getSessionStorage = (name) => {
      return JSON.parse(sessionStorage.getItem(name));
    }

    const saveInSessionStorage = (name, data) => {
      sessionStorage.setItem(name,  JSON.stringify(data));
    }

    const deleteStorage = (name) => {
      sessionStorage.removeItem(name);
    }

    const removeMask = (phone) => {
      if (phone.includes("(")) {
        phone = phone.replace("(", "");
      }
      if (phone.includes(")")) {
        phone = phone.replace(")", "");
      }
      if (phone.includes("-")) {
        phone = phone.replace("-", "");
      }
      if (phone.includes(" ")) {
        phone = phone.replace(" ", "");
      }
      return phone;
    }

    const getAccountType = () => {
      let account = accountType.value;
      switch (account) {
          case "administrator":
            account = "admin";
            break;
          case "member":
            account = "cuenta";
            break;
          case "member_analyst":
            account = "analyst";
            break;
          case "cabsa_member":
            account = "cuenta";
            break;
          case "supervisor":
            account = "supervisor";
            break;
          case "loan_analyst":
            account = "analyst";
            break;
          case "enroller": 
            account="adviser";
          break;
        }

        return account;
    }

    const loadInfo = () => {
      var auth = getTokenDecoden();
      accountType.value = auth.obj["role"];

      admissionRequestData.value = getSessionStorage('admissionRequestsinaptissste');
      identificationRegister.value = getSessionStorage('identificationFiles');
    };
    
    const saving_registration_attributes = (data) => {
      if(admissionRequestObject.value == null){
        return;
      }
      
      admissionRequestObject.value.data.saving_registration_attributes.payment_frecuency = data.payment_frecuency.toString().toLowerCase();
      admissionRequestObject.value.data.saving_registration_attributes.amount = data.amount == undefined ? "0" : fomartNumber(data.amount);
      admissionRequestObject.value.data.saving_registration_attributes.status = "activo";
      admissionRequestObject.value.data.saving_registration_attributes.payment_method = data.payment_method == "ViaNomina" ? "NÓMINA" : "DOMICILIACIÓN"
      admissionRequestObject.value.data.saving_registration_attributes.number_of_contribution = "0";
      admissionRequestObject.value.data.saving_registration_attributes.date_of_end = moment().format('YYYY-MM-DD');
      admissionRequestObject.value.data.saving_registration_attributes.date_of_start = moment().format('YYYY-MM-DD');
    }

    const bank_account_attributes = (data) => {
      if(admissionRequestObject.value == null){
        return;
      }

      admissionRequestObject.value.data.bank_account_attributes[0].account_number = data['0'].account_number.toString().replaceAll("-","");
      admissionRequestObject.value.data.bank_account_attributes[0].account_type = data['0'].account_type.toString().toLowerCase();
      admissionRequestObject.value.data.bank_account_attributes[0].bank_name = addUpperCase(data['0'].bank_name);
      admissionRequestObject.value.data.bank_account_attributes[0].expiration_month = data['0'].expiration_month.toString();
      admissionRequestObject.value.data.bank_account_attributes[0].expiration_year = data['0'].expiration_year.toString();
    }

    const fomartNumber = (value) => {
      return value.toString().replace(",","");
    }

    const getUploadFileResponse = (data) => {
      atachedDocuments.value = data.files;
      documentsUploaded.value = data.documentsUploaded;
      admissionRequestObject.value.data.enrollment_fee_payment_date = data.enrollmentFeePaymentDate;
      admissionRequestObject.value.data.enrollment_fee_method = "transferencia";
      if (data.enrollmentFeePaymentDate == "") {
        admissionRequestObject.value.data.enrollment_fee_payment_date = "";
        admissionRequestObject.value.data.enrollment_fee_method = "";
      }
    }

    const getDeletedFileResponse = (data) => {
      atachedDocuments.value = data.files;
      documentsUploaded.value = data.documentsUploaded;
      admissionRequestObject.value.data.enrollment_fee_payment_date = data.enrollmentFeePaymentDate;
      admissionRequestObject.value.data.enrollment_fee_method = "transferencia";
      if (data.enrollmentFeePaymentDate == "") {
        admissionRequestObject.value.data.enrollment_fee_payment_date = "";
        admissionRequestObject.value.data.enrollment_fee_method = "";
      }
    }

    const buttons_styles = {
      buttonSelected:"",
      activeColorBackground: '#EA7F0B !important',
      inactiveColorBackground: '#FFFFFF !important',
      activeColorText: '#FFFFFF !important',
      inactiveColorText: '#EA7F0B !important',
      activeBorderColor: '#EA7F0B',
      inactiveBorderColor: '#EA7F0B'
    }
    
    return {
      finishCapture,
      addressRef,
      workInfoRef,
      savingDataRef,
      viewClassViewWorkInfo,
      rulesValidationsWorkInfo,
      rulesValidationsSavingData,
      saving_registration_attributes,
      bank_account_attributes,
      viewClassViewSavingData,
      isLoading,
      activePaymentMethods,
      activeJobTypeOptions,
      activeJobStatus,
      documentsRequired,
      getUploadFileResponse,
      getDeletedFileResponse,
      viewClassViewUploadDocuments,
      keyTokeStorage,
      selectBusinessWorkInfo,
      viewClassViewAddress,
      rulesValidationsAddress,
      uploadFileRefs,
      ccApi,
      buttons_styles
    }
  },
  
  components: {
    Loading
  }
  
}
</script>
<style scoped>
.btn-next{
  background: #FFC001;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.sub-title {
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 400;
}
</style>