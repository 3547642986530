import api from '@/api/api'

const getStructureQuote = async (type) => {
    const resource = `/json/quote/${type}`

    const params = {
        baseURL: 'hostLocalWeb'
    }

    const config = {}

    try {
        return await api.httpGet(resource, params, config)
    } catch (error) {
        throw error;
    }
}

const getStructure = async () => {
    const resource = '/json/assistedEnrollment/structureValidations.json';

    const params = {
        baseURL: 'hostLocalWeb'
    }

    const config = {};

    try {
        return await api.httpGet(resource, params, config);
    } catch (error) {
        throw error;
    }
}

const getBenefits = async () => {
    const resource = '/json/benefits.json'

    const params = {
        baseURL: 'hostLocalWeb'
    }

    const config = {}

    try {
        return await api.httpGet(resource, params, config)
    } catch (error) {
        throw error;
    }
}

const getStructureValidations = async (url) => {
    //const resource = '/json/structureValidations.json'
    const params = {
        baseURL: 'hostLocalWeb'
    }

    const config = {}

    try {
        return await api.httpGet(url, params, config)
    } catch (error) {
        throw error;
    }
}

const validateRegex = (value, regex = /^[a-zA-ZÀ-ÿ\s]+$/) => {
    return regex.test(value)
}

const matchesCurpWithRfc = (rfc, curp) => {
    if (rfc.toString().toUpperCase().substring(0, 10) == curp.toString().toUpperCase().substring(0, 10))
        return true
    return false;
}

export default {
    getStructure: getStructure,
    validateRegex: validateRegex,
    matchesCurpWithRfc: matchesCurpWithRfc,
    getStructureValidations: getStructureValidations,
    getStructureQuote: getStructureQuote,
    getBenefits: getBenefits
}