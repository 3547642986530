import { uploadVoucher } from '@/api/document'
import api from '@/api/api'
const hostCCAPI = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

export default class Document {
  _listDocuments = [];
  _extensionsAllowed = [];

  constructor() {
    
  }

  get listDocuments() {
    return this._listDocuments;
  }

  set listDocuments(listDocuments) {
    this._listDocuments = listDocuments;
  }

  get extensionsAllowed() {
    return this._extensionsAllowed;
  }

  set extensionsAllowed(extensionsAllowed) {
    this._extensionsAllowed = extensionsAllowed;
  }

  getDocumentAttributes = (document) => {
    const attributes = {
      name: document[0].name,
      extension: document[0].type.split('/')[1],
      completeExtension: `.${document[0].type.split('/')[1]}`,
      size: document[0].size,
    }
    
    return attributes;
  }

  getDocumentAttributesSimple = (document) => {
    const attributes = {
      name: document.name,
      extension: document.type.split('/')[1],
      completeExtension: `.${document.type.split('/')[1]}`,
      size: document.size,
    }
    
    return attributes;
  }

  documentExtensionIsAllowed = (extensionsAllowed, extension) => {
    if (extensionsAllowed.includes(extension))
      return true;
    return false;
  }

  documentReachItsLimit = (maxDocumentsRequired, type="") => {
    if (type == "") {
      if (this._listDocuments.length >= maxDocumentsRequired) {
        return true;
      }
    } else {
      const listDocumentsByType = this._listDocuments.filter(d => d.abbreviation == type);
      if (listDocumentsByType.length >= maxDocumentsRequired) {
        return true;
      }
    }
    
    return false;
  }

  fileHasAlreadyBeenUploaded = (newDocument) => {
    if (this._listDocuments.length > 0) {
      let indexDocuments = this._listDocuments.findIndex(d => d.name === newDocument);
      if (indexDocuments != -1)
        return true;
      return false;
    }
    return false;
  }
  
  addDocument = (fileBase64, extension, name, type="", remote_folder="documentos_alta_socios") => {
    const newDocument = {
      name: name,
      file: fileBase64,
      extension: extension,
      abbreviation: type,
      admission_request_id: 0,
      remote_folder: remote_folder
    }

    this._listDocuments.push(newDocument);

    return newDocument;
  }

  deleteDocument = (fileName, type="") => {
    try {
      if (this._listDocuments.length > 0) {
        const index = type == "" 
        ? this._listDocuments.findIndex(a => a.name == fileName) 
        : this._listDocuments.findIndex(a => a.name == fileName && a.abbreviation == type);

        if (index != -1)
          this._listDocuments.splice(index, 1)
      }
      return this._listDocuments;
    } catch (error) {
      throw "Error al intentar eliminar documento"
    }
  }
  
  exceedTheMaximunSize = (maxSize, size) => {
    if (size > maxSize)
      return true
    return false
  }
  uploadVoucher = (token_auth, file, extension, payment_method, admission_request_id, payment_date, income_source_name) => {
    return uploadVoucher(token_auth, file, extension, payment_method, admission_request_id, payment_date, income_source_name).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  getFileByIdSelector = (selector) => {
    document.getElementById(selector).value = "";
    document.getElementById(selector).click();
  }

  serialize = (file) => {
    const fileReader = new FileReader();
    
    return new Promise((resolve, reject) => {
      fileReader.onload = (fileEvent) => {
        let fileBase64 = fileEvent.target.result.split(',')[1];
        resolve(fileBase64);
      }

      fileReader.onerror = () => {
        reject('error, something went wrong with the file reader.')
      }

      fileReader.readAsDataURL(file);
    });
  }

  getDocumentsRequired = async (tokenAuth, requiredIn) => {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/documents/get_documents_requiered`
    const apiUrl = `${hostCCAPI}${resource}`

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      token_auth: tokenAuth,
      requeried_in: requiredIn
    }

    try {
      return await api.httpGet(apiUrl, config, params); 
    } catch (error) {
      throw error.response;
    }
  }

  createDocumentsRequiredArray = (documentsRequired) => {
    return documentsRequired.map(d => ({
      abbreviation: d.abbreviation,
      documentAttached: false,
      id: d.id,
      name: d.name,
      max_documents_required: d.max_documents_required,
      allowedExtensions: d.extension,
      optional: d.optional,
      viewable: true
    }))
  }

  validateTypeFormat = (formats, typeFormat) => {
    for(const format of formats){
      if(format==typeFormat) return true
    }

    return false;
  }

  exceedTheMaximunSize = (maxSize, size) => {
      return size > maxSize;
  }

  save = (tokenAuth, documents) => {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/upload_documents_for_registration`
    const apiUrl = `${hostCCAPI}${resource}`

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      token_auth: tokenAuth,
      document_list: documents
    }

    try {
      return api.httpPost(apiUrl, config, params); 
    } catch (error) {
      throw error.response;
    }
  }

  identificationNumberVerification = async (token, identificationType, identificationNumber) => {
    const resource = `/${business}/person/identification_number_exists`;
    const API_URL = `${hostCCAPI}${resource}`;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "token_auth": token,
        },
        "identification_type": identificationType,
        "identification_number": identificationNumber
    };

    const params = {
        token_auth: token,
        identification_type: identificationType,
        identification_number: identificationNumber
    };

    return await api.httpGet(API_URL, {config}, params);
  }
  
  downloadAllDocuments = async (token, remoteFolder, admissionRequestId) => {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/download_all_documents`;
    const API_URL = `${hostCCAPI}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json",
            "token_auth": token,
        },
        "remote_folder": remoteFolder,
        "admission_request_id": admissionRequestId
    };

    const params = {
        token_auth: token,
        remote_folder: remoteFolder,
        admission_request_id: admissionRequestId
    };

    return await api.httpGet(API_URL, {config}, params);
    
  }
}