import enums from '@/helpers/enums';
import moment from "moment";

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
]

const monthsInNumbers = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12"
]

function getRangeOfDatesByYears(maxYears) {
  let currentDate = new Date();
  let range = [];

  var pastDate=new Date(currentDate);
  pastDate.setDate(pastDate.getDate() - maxYears);

  range = [convertDateToString(pastDate), convertDateToString(currentDate)]
  
  return range;
}

function getCurrentDate() {
  var today = new Date();
  var month = today.getMonth() + 1; //months from 1-12
  var day = today.getDate();
  var year = today.getFullYear();
  
  if (month.toString().length < 2)
      month = '0' + month;
  if (day.toString().length < 2)
      day = '0' + day;

  return `${year}-${month}-${day}`;
}

function convertDateToString(date) {
  var month = date.getMonth() + 1; //months from 1-12
  var day = date.getDate();
  var year = date.getFullYear();
  
  if (month.toString().length < 2)
      month = '0' + month;
  if (day.toString().length < 2)
      day = '0' + day;

  return `${year}-${month}-${day}`;
}

function getRangeOfYears(maxYears) {
  let date = new Date();
  let years = [];

  let currentYear = date.getFullYear();
  let startYear = currentYear - maxYears;

  while (startYear <= currentYear) {
    let year = startYear++;
    years.push(year.toString());
  }

  return years.reverse();
}

function getDaysByYearAndMonth (year, month, remaining = true) {
  let date = new Date(year, month - 1, 1);
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = (currentDate.getMonth() + 1);
  let currentDay = currentDate.getDate();
  let firstDayOfMonth = 1;
  let result = [];
  let day = 0;

  if (parseInt(year) == currentYear && parseInt(month) == currentMonth && remaining) {
    for(let index=currentDay;index<=(new Date(year).getDate());index++){
      day = index;

      if(day.toString().length <= 1)
        day = `0${day}`;

      result.push(day.toString());
    }
  } else {
    while (date.getMonth() == month - 1) {
      day = date.getDate();
  
      if(day.toString().length <= 1)
        day = `0${day}`;
  
      result.push(day.toString());
      date.setDate(date.getDate() + 1);
    }
  }
  return result;
}

function formatIsoDate(date, character) {
  var parts = date.split(character);
  
  var year = parts[2];
  var month = parts[1];
  var day = parts[0];
  
  var dateConvert = year + "-" + month + "-" + day ;
  
  return dateConvert;
}

function getMonthsOfYearInLetters(yearSelected, remaining = true){
  if(!remaining) return months;

  let date = new Date();
  let numericMonths = [];
  let stringMonths = [];
  let firstMonthOfYear = 1;
  let currentYear = date.getFullYear();
  let currentMonth = (date.getMonth() + 1);
  if (parseInt(yearSelected) == currentYear) {
    for(let index=currentMonth;index<=12;index++){
        let month=index;
        if (index.toString().length < 2) {
          month = `0${index}`
        }
        numericMonths.push(month.toString());
    }
    if (numericMonths.length > 0)
      numericMonths.forEach(e => stringMonths.push(enums.largeMonths[e])); 
  }
   else {
    stringMonths = months;
  }
  return stringMonths;
}

function getRemainingMonthsOfTheYearInLetters(yearSelected){
  let date = new Date();
  let currentYear = date.getFullYear();
  let currentMonth = (date.getMonth() + 1);
  let numericMonths = [];
  let stringMonths = [];
  if (parseInt(yearSelected) == currentYear) {
     
      for(let index=1; index<=parseInt(currentMonth); index++){
          let month=index;
          if (index.toString().length <= 2) {
              month = `0${index}`
          }
          numericMonths.push(month.toString());
      }

      if (numericMonths.length > 0)  numericMonths.forEach(e => stringMonths.push(enums.largeMonths[e])); 
  
  } else {
    stringMonths = months;
  }
  
  return stringMonths;
}

function getMonthsOfYearInNumbers(yearSelected){
  let date = new Date();
  let numericMonths = [];
  let stringMonths = [];
  let firstMonthOfYear = 1;
  let currentYear = date.getFullYear();
  let currentMonth = (date.getMonth() + 1);

  if (parseInt(yearSelected) == currentYear) {
    while (firstMonthOfYear <= currentMonth) {
      let month = firstMonthOfYear++;
      if (month.toString().length < 2) {
        month = `0${month}`
      }
      numericMonths.push(month.toString());
    }
  } else {
    numericMonths = monthsInNumbers;
  }

  return numericMonths;
}

function createDateStringByYearMonthDay(year, month, day) {
  let date = `${year}-${month}-${day}`;
  return date;
}

function getAge(stringDate) {
  let date = new Date();
  let stringDateSplit = stringDate.split('-');
  let currentYear = date.getFullYear();
  let currentMonth = (date.getMonth() + 1);
  let currentDay = date.getDate();

  let age = currentYear - parseInt(stringDateSplit[0]);

  if (parseInt(stringDateSplit[1]) > currentMonth) {
    age--;
  }

  if (parseInt(stringDateSplit[1]) == currentMonth) {
    if (parseInt(stringDateSplit[2]) > currentDay) {
      age--;
    }
  }

  return age;
}

function isUnderAge(age, minAge) {
  return age >= minAge ? false : true;
}


function getRangeOfYearsFuture(maxYears) {
  let date = new Date();
  let years = [];

  let currentYear = date.getFullYear();
  let startYear = currentYear + maxYears;

  while (currentYear<= startYear) {
    let year = startYear--;
    years.push(year.toString());
  }

  return years.reverse();
}

function getAllDaysByYearAndMonth (year, month) {
  let date = new Date(year, month - 1, 1);
  let result = [];
  let day = 0;
  
  while (date.getMonth() == month - 1) {
      day = date.getDate();
  
      if(day.toString().length <= 1)
        day = `0${day}`;
  
      result.push(day.toString());
      date.setDate(date.getDate() + 1);
  }
  return result;
}

function getMonths(){
  return months;
}

function getMonthsInNumbers(){
  return monthsInNumbers;
}

function date_select_is_future(date) {
  date = (date.split("/")).reverse().join("-");
  return (moment(date).format("YYYY-MM-DD")) > (moment(new Date()).format("YYYY-MM-DD"))
}

function isBeforeDate({ start_date, end_date}) {
  var startDate = moment(start_date);
  var endDate = moment(end_date);
  return startDate < endDate;
}

function isAfterDate({start_date, end_date}) {
  var startDate = moment(start_date);
  var endDate = moment(end_date);
  return startDate > endDate;
}

function isEqualDate({start_date, end_date}) {
  const format = 'YYYY-MM-DD';
  
  const startDate = moment(start_date, format);
  const endDate = moment(end_date, format);

  return startDate.isSame(endDate);
}

function normalizeDateFormat(date){
  if(date == undefined || date == null || date == ""){
      return moment().format("DD/MM/YYYY");
  }

  if(date.length > 10){
      date = date.substring(0,10)
  }

  date = date.replaceAll("-","/");
  if(!date.substring(0,4).includes("/")){
      return moment(date).format("DD/MM/YYYY");
  }
  return date
}

function getAgeByBirthDate(birthdate) {
  let date = birthdate.toString().split('/').reverse();
  birthdate = `${date[0]}-${date[1]}-${date[2]}`;
  var dateOfBirth = new Date(birthdate);
  var currentDate = new Date();
  var age = currentDate.getFullYear() - dateOfBirth.getFullYear();
  var m = currentDate.getMonth() - dateOfBirth.getMonth();
  if (m < 0 || (m === 0 && currentDate.getDate() < dateOfBirth.getDate())) {
      age--;
  }

  return age;
}

function subtractDateByNumberOfLapses(number, type= "year"){
  // Definir la fecha
  const currentDate = moment();

  // Restar 1 año a la fecha
  const dateSubstract = currentDate.subtract(number, type);

  return dateSubstract.format('YYYY-MM-DD');
}

function getYearsRemaining(date, year) {
  return year - getYears(date);
}

function getYears(birthday) {
  birthday = moment(birthday);
  let today = moment();
  let age = today.diff(birthday,"years");
  return age;
}


function addYearsToCurrentDate(years){
  return moment().add(years, 'years').format('YYYY-MM-DD');
}

function getAgeByDate(date) {
  let birthday = moment(date);
  let today = moment();
  let age = today.diff(birthday,"years");
  return age;
}

export {
  getRangeOfDatesByYears,
  getCurrentDate,
  convertDateToString,
  getRangeOfYears,
  getDaysByYearAndMonth,
  getMonthsOfYearInLetters,
  getRemainingMonthsOfTheYearInLetters,
  createDateStringByYearMonthDay,
  getMonthsOfYearInNumbers,
  getAge,
  isUnderAge,
  getRangeOfYearsFuture,
  getAllDaysByYearAndMonth,
  getMonths,
  getMonthsInNumbers,
  date_select_is_future,
  getAgeByBirthDate,
  formatIsoDate,
  isBeforeDate,
  isAfterDate,
  isEqualDate,
  normalizeDateFormat,
  subtractDateByNumberOfLapses,
  getYearsRemaining,
  getYears,
  addYearsToCurrentDate,
  getAgeByDate
}