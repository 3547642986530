<template>
  <div class="mx-auto header_principal" style="">
    <header>
      <div class="">
        <div class="header">
          <div class="">
            <img src="@/assets/logo-heaedr.png" class="header-img" alt="" />
            <div class="container_menu_icon" v-on:click="openMenu()">
              <a class="menu__icon mobile"><span></span></a>
            </div>
          </div>
          <!-- <div class="col-span-6" style="display:flex;justify-content:flex-end;text-align:right;">
            <div style="display:table;margin-right:15px;" class="containerInfoHeader">
              <div style="display:table-cell;vertical-align:middle">
                <p class="nameHeader">{{camelize(fullName)}}</p>
              </div>
            </div>
            <div class="containerUserImageHeader">
              <img src="@/assets/user-ico.svg" alt="" style="width:60px;">
            </div>
          </div> -->
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  beforeMount() {
    this.$store.dispatch("setOpenMobileMenuAccountAction", false);
  },
  methods: {
    openMenu() {
      let windowWith = window.innerWidth;
      let windowHeight = window.innerHeight;
      let open = false;
      if (windowWith >= 767) {
        open = this.$store.getters.getOpenLateralMenuAccount;
        open = !open;
        this.$store.dispatch("setOpenMobileMenuAccountAction", false);
        this.$store.dispatch("setOpenLateralMenuAccountAction", open);
      } else {
        open = this.$store.getters.getOpenMobileMenuAccount;
        open = !open;
        this.$store.dispatch("setOpenLateralMenuAccountAction", false);
        this.$store.dispatch("setOpenMobileMenuAccountAction", open);
      }
    },
    camelize(str) {
      let response = "";
      if (str != "") {
        str = str
          .toLowerCase()
          .replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
            return match.charAt(match.length - 1).toUpperCase();
          });
        response = str.charAt(0).toUpperCase() + str.substring(1);
        return response.replace(/([A-Z])/g, " $1").trim();
      }
      return "";
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  background-color: #fff;
  border-bottom: 2px solid #e6e6e6;
  padding: 10px 25px 10px 25px;
}

h1 {
  margin-top: 0px !important;
}
.header-img {
  width: 220px !important;
}
.container_menu {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.container_menu_icon {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 20;
  cursor: pointer;
  right: 20px;
  top: 15px;
}

.menu__icon {
  height: 20px;
  width: 28px;
  margin: 3%;
  position: absolute;
  top: 9px;
  right: 14%;
  display: inline-block;
  vertical-align: middle;
  z-index: 20;
}

.menu__icon span {
  display: block;
  background: #690b2f;
  width: 100%;
  height: 4px;
  margin-top: -2px;
  position: absolute;
  right: 0;
  top: 50%;
}

.menu__icon:before,
.menu__icon:after {
  content: "";
  display: block;
  background: #690b2f;
  font-weight: bold;
  width: 100%;
  height: 4px;
  position: absolute;
  right: 0;
  transform-origin: center center;
  transform: rotate(0deg);
  transition: all 0.3s ease;
  opacity: 1 !important;
}

.menu__icon:before {
  top: 2px;
  margin-top: -2px;
}

.menu__icon:after {
  bottom: 2px;
  margin-bottom: -2px;
}
/* 
.menu_shown .menu__icon span {
  background: transparent;
}

.menu_shown .menu__icon:before {
  top: 50%;
  transform: rotate(45deg);
}

.menu_shown .menu__icon:after {
  bottom: 50%;
  transform: rotate(-45deg);
} */

.imgUserHeader {
  width: 60px;
}
.containerInfoHeader {
  display: block;
}

.containerUserImageHeader {
  display: block;
}

.mobile.accordion-menu {
  position: absolute;
  right: 0;
  width: 100%;
  min-height: auto;
  padding-top: 0px;
  z-index: 10;
  /* transition: all 0.4s ease-in-out;
  transform: translateX(100%); */
}

.nameHeader {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  color: #726659;
  margin-bottom: 0px !important;
}
.rfcHeader {
  font-family: Roboto;
  font-weight: 300;
  font-size: 16px;
  color: #726659;
  margin-bottom: 0px !important;
}

.menu_shown .mobile.accordion-menu {
  /* transform: translateX(0); */
}

.mobile .menu__item {
}

@media (max-width: 767px) {
  .containerInfoHeader {
    display: none !important;
  }
  .containerUserImageHeader {
    display: none !important;
  }
}

@media (max-width: 400px) {
  .containerInfoHeader {
    display: none !important;
  }
  .containerUserImageHeader {
    display: none !important;
  }
}

@media (min-width: 767px) {
  nav {
    padding: 3% 6%;
  }

  .desktop {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .container_menu,
  .container_menu_icon {
    display: none;
  }
}
</style>