<template>
     <div class="vld-parent">
        <loading :active="isLoading" 
        :can-cancel="true" 
        :on-cancel="onCancel"
        :color="'#810042'"
        :height="120"
        :width="400"
        :is-full-page="fullPage"></loading>
    </div>
</template>

<script>
    // Import component
    import Loading from 'vue3-loading-overlay';
    // Import stylesheet
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
    
    export default {
        name: "VueLoading",
        data() {
            return {
                fullPage: true
            }
        },
        props: {
            isLoading: Boolean
        },
        components: {
            Loading
        },
        methods: {
            doAjax() {
                this.isLoading = false;
                // simulate AJAX
                setTimeout(() => {
                  this.isLoading = false
                },5000)
            },
            onCancel() {
              console.log('User cancelled the loader.')
            }
        }
    }
</script>
