import { post } from 'axios'

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

function uploadVoucher (token_auth, file, extension, payment_method, admission_request_id, payment_date, income_source_name) {
  const resource = `/${business}/enroll_payment_fee/upload_receipt`
  const API_URL = `${host}${resource}`
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };

  const params = {
    token_auth: token_auth,
    income_source_name: income_source_name,
    file: file,
    extension: extension,
    payment_method: payment_method,
    admission_request_id: admission_request_id,
    enrollment_fee_payment_date: payment_date
  }
  
  return post(API_URL,  params, config )
}

export {
  uploadVoucher
}